import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { getNodeStatusAPI } from "../../../../service/ai-model.service";
import { faCheckCircle, faCirclePause, faCirclePlay, faCircleXmark, faHourglass, faPlayCircle, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { useAIWorkflowContext } from "../../../../context/ai-workflow.context";

const Status = {
    Success: 'text-success',
    Failure: 'text-danger',
    NotStared: 'text-draft'
}
const NodeStatus = ({ id }) => {
    const { workflowExecution } = useAIWorkflowContext();

    const getConfig = () => {
        let config = {
            icon: faRefresh,
            class: `${Status.NotStared} fa-spin`
        };
        if (workflowExecution && !workflowExecution.loading) {
            return workflowExecution.errorNode?.includes(id) ? {
                icon: faCircleXmark,
                class: Status.Failure
            } : workflowExecution.successNode?.includes(id) ? {
                icon: faCheckCircle,
                class: Status.Success
            } : {
                icon: faCirclePause,
                class: Status.NotStared
            };
        }

        return config
    }

    const render = () => {
        if (workflowExecution) {
            const config = getConfig();
            return <FontAwesomeIcon
                icon={config.icon}
                size='1x'
                className={config.class} />
        }
    }


    return render()
}

export default NodeStatus