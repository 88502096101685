import React, { useEffect, useState } from "react";
import { useAIModelContext } from "../../../context/ai-model.context";
import RecentModelItem from "./RecentModelItem";
import constant, { TemplateStatus } from "../../../helper/constant";
import { useScreenSizeContext } from "../../../context/mobile-layout.context";
import { Link } from "react-router-dom";

const RecentModels = () => {
  const { templates } = useAIModelContext();
  const [keyword, setKeyword] = useState("");
  const [templateList, setTemplateList] = useState([]);
  const { isMobileScreen } = useScreenSizeContext();

  useEffect(() => {
    if (templates) setTemplateList([...templates]);
  }, [templates]);

  useEffect(() => {
    if (keyword) {
      const temps = [...templates].filter(
        (zz) =>
          zz.template_name?.toLowerCase().includes(keyword.toLowerCase()) ||
          zz.status?.toLowerCase().includes(keyword.toLowerCase())
      );
      setTemplateList(temps);
    } else setTemplateList(templates);
  }, [keyword, templates]);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const renderModelItem = (list) => {
    return list.map((template) => (
      <RecentModelItem key={template.id} template={template} />
    ));
  };

  const renderNewModels = () => {
    const list = templateList.filter(
      (zz) =>
        zz.status === TemplateStatus.DRAFT || zz.status === TemplateStatus.NEW
    );
    if (list.length)
      return (
        <>
          <h4 className="subheading1 position-relative pb-3 my-3 text-center">
            Draft
          </h4>
          <div className="row">{renderModelItem(list)}</div>
        </>
      );
  };

  const renderScheduledModels = () => {
    const list = templateList.filter(
      (zz) => zz.status === TemplateStatus.SCHEDULED
    );
    if (list.length)
      return (
        <>
          <h4 className="subheading2 position-relative pb-3 my-3 text-center">
            Scheduled
          </h4>
          <div className="row mb-3">{renderModelItem(list)}</div>
        </>
      );
  };

  const renderCompletedModels = () => {
    const list = templateList.filter(
      (zz) => zz.status === TemplateStatus.COMPLETED
    );
    if (list.length)
      return (
        <>
          <h4 className="subheading3 position-relative pb-3 my-3 text-center">
            Completed
          </h4>
          <div className="row">{renderModelItem(list)}</div>
        </>
      );
  };

  const renderTabHeaders = () => {
    return (
      <ul className="nav nav-pills workflowtab-title" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <div className="nav-link1 workflowtab-title-box active" id="pipeline-completed-tab" data-toggle="pill"
            data-target="#pipeline-completed" type="button" role="tab" aria-controls="pipeline-completed"
            aria-selected="false">
            <div className="recentmodelbox shadow-none">
              <div className="edtmdlbtn edtmdlbtn2 greenbg px-2 position-relative ai-recent-tab-buttons">
                <svg width="15" height="15" viewBox="0 0 23 23" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.6443 1.34375L9.10566 15.2381L2.875 9.01191L0 11.8869L9.58184 21.4688L23 4.21875L19.6443 1.34375Z"
                    fill="white" />
                </svg>

              </div>
              <div className="pb-3 text-center font-weight-bold">Completed</div>
            </div>
          </div>
        </li>
        <li className="nav-item" role="presentation">
          <div className="nav-link1 workflowtab-title-box" id="pipeline-scheduled-tab" data-toggle="pill"
            data-target="#pipeline-scheduled" role="tab" aria-controls="pipeline-scheduled"
            aria-selected="false">
            <div className="recentmodelbox border-left border-right shadow-none">
              <div className="edtmdlbtn edtmdlbtn2 yellowbg px-2 position-relative ai-recent-tab-buttons">
                <svg width="16" height="16" viewBox="0 0 18 18" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172936 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89471 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9975 6.61382 17.0485 4.3261 15.3612 2.63882C13.6739 0.95154 11.3862 0.00251984 9 0ZM13.8462 9.69231H9C8.81639 9.69231 8.6403 9.61937 8.51047 9.48953C8.38063 9.3597 8.30769 9.18361 8.30769 9V4.15385C8.30769 3.97023 8.38063 3.79414 8.51047 3.66431C8.6403 3.53448 8.81639 3.46154 9 3.46154C9.18361 3.46154 9.35971 3.53448 9.48954 3.66431C9.61937 3.79414 9.69231 3.97023 9.69231 4.15385V8.30769H13.8462C14.0298 8.30769 14.2059 8.38063 14.3357 8.51046C14.4655 8.6403 14.5385 8.81639 14.5385 9C14.5385 9.18361 14.4655 9.3597 14.3357 9.48953C14.2059 9.61937 14.0298 9.69231 13.8462 9.69231Z"
                    fill="#FFFEFE" />
                </svg>
              </div>
              <div className="pb-3 text-center font-weight-bold">Scheduled</div>
            </div>
          </div>
        </li>
        <li className="nav-item1" role="presentation">
          <div className="workflowtab-title-box" id="pipeline-drafts-tab" data-toggle="pill"
            data-target="#pipeline-drafts" role="tab" aria-controls="pipeline-drafts"
            aria-selected="true">
            <div className="recentmodelbox shadow-none">
              <div className="edtmdlbtn edtmdlbtn2 bluebg px-2 position-relative ai-recent-tab-buttons">
                <svg width="14" height="14" viewBox="0 0 18 18" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_4690_14655)">
                    <path
                      d="M15.9106 11.4876L16.8515 15.548C16.8839 15.6964 16.8828 15.8502 16.8482 15.9982C16.8136 16.1461 16.7464 16.2844 16.6515 16.4031C16.5566 16.5217 16.4363 16.6177 16.2996 16.6839C16.1629 16.7501 16.0131 16.785 15.8611 16.7859C15.7903 16.793 15.719 16.793 15.6482 16.7859L11.5631 15.8451L3.71973 8.0314L8.09697 3.66406L15.9106 11.4876Z"
                      fill="white" />
                    <path
                      d="M1.25462 4.11828L4.17608 1.19682C4.36815 1.00572 4.62808 0.898437 4.89902 0.898438C5.16997 0.898438 5.42989 1.00572 5.62196 1.19682L7.24609 2.82095L2.8738 7.19324L1.24967 5.56911C1.15462 5.47358 1.07935 5.36025 1.02815 5.2356C0.976948 5.11095 0.950832 4.97742 0.951292 4.84267C0.951752 4.70791 0.978779 4.57457 1.03083 4.45027C1.08288 4.32597 1.15892 4.21315 1.25462 4.11828Z"
                      fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0_4690_14655">
                      <rect width="17.8259" height="17.8259" fill="white"
                        transform="matrix(-1 0 0 1 18 0)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="pb-3 font-weight-bold  text-center">Draft</div>
            </div>
          </div>
        </li>
      </ul>
    )
  }

  const renderTabContent = () => {
    return (
      <div className="tab-content workflowtab-wrap-box p-4 bg-white recent-model-box" id="pills-tabContent">
        <div className="tab-pane fade" id="pipeline-drafts" role="tabpanel"
          aria-labelledby="pipeline-drafts-tab">
          {!isMobileScreen && renderNewModels()}
        </div>
        <div className="tab-pane fade" id="pipeline-scheduled" role="tabpanel"
          aria-labelledby="pipeline-scheduled-tab">{!isMobileScreen && renderScheduledModels()}</div>
        <div className="tab-pane fade show active" id="pipeline-completed" role="tabpanel"
          aria-labelledby="pipeline-completed-tab">{renderCompletedModels()}</div>
      </div>
    )
  }

  const render = () => {
    return (
      <>
        <div className="d-flex align-items-center mt-3 mb-4 justify-content-between">
          <div className=" h6 position-relative font-weight-bold">Pipelines</div>
          <Link className="createnewbtn" to={constant.PAGES.HOME.AI_TEMPLATE}>
            <span className="btnplus">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.48611 0V5.875H14.3611V8.48611H8.48611V14.3611H5.875V8.48611H0V5.875H5.875V0H8.48611Z"
                  fill="#FDFBFB" />
              </svg>
            </span> <span className="createnewbtntext">Create New </span>
          </Link>
        </div>
        {renderTabHeaders()}
        {renderTabContent()}
      </>
    )
  }
  return render();
};

export default RecentModels;
