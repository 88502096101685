import React, { useEffect } from 'react';
import { AvailableNodes, NodeType, TemplateStatus } from '../../../../helper/constant';
import { Handle, Position } from '@xyflow/react';
import { useAIModelContext } from '../../../../context/ai-model.context';
import { useAIWorkflowContext } from '../../../../context/ai-workflow.context';
import { renderNodeIcon } from '../../../../helper/helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import NodeStatus from '../components/NodeStatus';

const DEFAULT_HANDLE_STYLE = {
    bottom: -5,
};

const InputNode = ({ data, id, setNodes, workflow }) => {
    const { showRightMenu, updateRightMenu } = useAIModelContext();
    const { updateSelecteNode, selectedNode } = useAIWorkflowContext();

    useEffect(() => {

    }, [selectedNode]);

    const handleNodeClick = () => {
        updateRightMenu(true);
        updateSelecteNode({ id, data })
    };

    const renderNodeDetails = () => {
        if (data && data.input_type)
            return (
                <div className='p-2 node-details'>
                    <p className='p-0 m-0 text-black'>Input: <span className='text-secondary capitalize'>{data.input_type}</span></p>
                    {data.scrapping_url && <p className='p-0 m-0 text-black'>URL: <span className='text-secondary'>{data.scrapping_url}</span></p>}
                </div>
            )
    }

    const render = () => {
        if (data)
            return (
                <div className={`dndnode d-flex flex-column gap-1 ${NodeType.InputNode}`} onClick={handleNodeClick}
                >
                    {data &&
                        <div className='p-2 m-0 node-title d-flex justify-content-between align-items-center' style={{ backgroundColor: AvailableNodes[0].color }}>
                            <div className='d-flex align-items-center'>
                                {renderNodeIcon(data.type, 25)}
                                <p className='p-0 m-0'>{data.label}</p>
                            </div>
                            {workflow.status === TemplateStatus.COMPLETED && <NodeStatus id={id} />}
                        </div>
                    }
                    {renderNodeDetails()}
                    <Handle
                        type="source"
                        position="right"
                        style={{ background: '#555' }}
                        id={`${id}-source`}
                        isConnectable={true}
                    />
                </div >
            )
    }
    return render();
}

export default InputNode