import React, { createContext, useContext } from "react";
import * as ACTION from "./../reducers/types";
import { useToasts, AppearanceTypes } from "react-toast-notifications";
import constant, { ToastType } from "../helper/constant";
import useAIWorkflowReducer from "../reducers/ai-workflow.reducer";
import { createWorkflowAPI, deleteWorkflowAPI, getWorkflowInputTypesAPI, getWorkflowListAPI, getWorkflowLLMListAPI, getWorkflowModelListAPI } from "../service/ai-model.service";

const WorkflowContext = createContext();

const WorkflowProvider = ({ children }) => {
    const { state, dispatch } = useAIWorkflowReducer();
    const { addToast } = useToasts();

    const updateSelecteNode = payload => dispatch({ type: ACTION.UPDATE_SELECTED_NODE, payload });

    const updateSelecteWorkflow = payload => dispatch({ type: ACTION.UPDATE_SELECTED_WORKFLOW, payload });

    const getWorkflowList = payload => {
        getWorkflowListAPI()
            .then((result) => {
                const list = result.filter(item => item.active === 1);
                dispatch({ type: ACTION.UPDATE_WORKFLOW_LIST, payload: list })
            })
            .catch(e => console.log(e))
    }


    const getWorkflowInputTypes = payload => {
        if (state.inputTypes.length === 0)
            getWorkflowInputTypesAPI()
                .then((result) => {
                    const list = result.map(item => ({ ...item, value: item.type }));
                    dispatch({ type: ACTION.UPDATE_WORKFLOW_INPUT_LIST, payload: list })
                })
                .catch(e => console.log(e))
    }

    const createWorkflow = (payload, id, cb) => {
        dispatch({ type: ACTION.UPDATE_LOADER, payload: true });
        createWorkflowAPI(payload, id)
            .then((r) => {
                const list = state.workflows.filter((zz) => zz.id !== id);
                dispatch({
                    type: ACTION.UPDATE_WORKFLOW_LIST,
                    payload: { list },
                });
                cb && cb(r.id);
            })
            .catch((ex) => {
                handleError(ex?.message);
            })
            .finally(() => dispatch({ type: ACTION.UPDATE_LOADER, payload: false }));
    };


    const deleteWorkflow = (id) => {
        dispatch({ type: ACTION.UPDATE_LOADER, payload: true });
        deleteWorkflowAPI(id)
            .then((r) => {
                const list = state.workflows.filter((zz) => zz.id !== id);
                dispatch({
                    type: ACTION.UPDATE_WORKFLOW_LIST,
                    payload: { list },
                });
            })
            .catch((ex) => {
                handleError(ex?.message);
            })
            .finally(() => dispatch({ type: ACTION.UPDATE_LOADER, payload: false }));
    };


    const getWorkflowModelList = () => {
        if (state.workflowModels.length === 0)
            getWorkflowModelListAPI()
                .then(list => {
                    list = list.map(item => ({ ...item, taskId: item.id, id: item.name }))
                    dispatch({ type: ACTION.UPDATE_WORKFLOW_MODLE_LIST, payload: list });
                })
                .catch((ex) => ex)
    }

    const getWorkflowLLMList = () => {
        if (state.llmList.length === 0)
            getWorkflowLLMListAPI()
                .then(list => {
                    list = list
                        .map(item => ({ ...item, value: item.model_type }))
                        .filter(item => item.active);
                    dispatch({ type: ACTION.UPDATE_WORKFLOW_LLM_LIST, payload: list });
                })
                .catch((ex) => ex)
    }

    const clearWorkflowInfo = () => dispatch({ type: ACTION.CLEAR_WORKFLOW_INFO });

    const updateWorkflowEdit = flag => dispatch({ type: ACTION.UPDATE_WORKFLOW_EDIT, payload: flag });

    const updateWorkflowExecution = payload => dispatch({ type: ACTION.UPDATE_WORKFLOW_EXECUTION, payload });


    const handleError = (e, ignore) => {
        if (!ignore)
            addToast(e || constant.MESSAGES.COMMON_ERROR_MESSAGE, {
                appearance: "error",
                autoDismiss: false,
            });
    };

    return (
        <WorkflowContext.Provider
            value={{
                ...state,
                updateSelecteNode,
                updateSelecteWorkflow,
                getWorkflowList,
                getWorkflowModelList,
                createWorkflow,
                deleteWorkflow,
                clearWorkflowInfo,
                getWorkflowInputTypes,
                getWorkflowLLMList,
                updateWorkflowEdit,
                updateWorkflowExecution
            }}
        >
            {children}
        </WorkflowContext.Provider>
    );
};

export const useAIWorkflowContext = () => useContext(WorkflowContext);

export { WorkflowProvider, WorkflowContext };
